<ng-container *ngIf="userLoggedIn; then loggedIn; else loggedOut">
</ng-container>

<ng-template #loggedOut>
  <div class="viv-header">
    <div class="container">
      <div class="viv-header-title center-logo">
        <app-logo></app-logo>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loggedIn>
  <div class="viv-header">
    <div class="container">
      <ng-container *ngIf="userLoggedIn">
        <div class="viv-subheader">
          <div class="viv-header-title" (click)="navigateToDashboard()" [ngStyle]="userLoggedIn ? {'cursor' : 'pointer'} : {'cursor' : 'default'}">
            <app-logo></app-logo>
          </div>
          <div class="viv-header-menu">
            <button mat-button [matMenuTriggerFor]="menu">
              <div class="viv-button-content">
                <div class="viv-button-text-wrapper">
                  <div class="viv-button-text">{{ initials }}</div>
                </div>
                <div class="viv-caret">
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </div>
              </div>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <div mat-menu-item>
                <div class="user-info">
                  <span><strong>{{ name }}</strong></span>
                  <span>{{ emailAddress }}</span>
                </div>
              </div>
              <button mat-menu-item (click)="navigateToMyAccount()">
                <app-vivid-svg-icon icon="doctor_outline"></app-vivid-svg-icon>
                My account
              </button>
              <button mat-menu-item *ngIf="isSupervisor" (click)="navigateToWorkforce()">
                <app-vivid-svg-icon icon="public_health_outline"></app-vivid-svg-icon>
                Workforce
              </button>
              <button mat-menu-item (click)="signOut()">
                <app-vivid-svg-icon icon="exit_outline"></app-vivid-svg-icon>
                Log out
              </button>
            </mat-menu>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-template>
