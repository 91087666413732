import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { ClientMetaData } from '@aws-amplify/auth/lib-esm/types';
import { environment } from '@environments/environment';
import { ICredentials } from '@aws-amplify/core/lib-esm/types';
import {
  CognitoUser as VividCognitoUser,
  UpdatableAttributes,
} from '../models/models';
import { ISignUpResult, CognitoUser } from 'amazon-cognito-identity-js';
import { CognitoCodeErrors } from '../enums/common-enums';
import { dummyPassword } from '../constants/constants';
import { isObjectEmpty, mergeArrayOfObjects } from '../utils/utils';
import { PhcAppService } from './phcapp.service';
import { IdpUserAttribute } from '../models/types';
import { HttpService } from './http.service';
import { getHttpHeaders } from '../utils/common.utils';
import { HttpHeaders } from '@angular/common/http';
import { UserRoleTypesEnum } from '@common/enums/user-role-enum';

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  private _isUserAuthenticated: BehaviorSubject<boolean>;
  private _cognitoUser: CognitoUser | any = <CognitoUser>{};
  private _username: string = '';
  private _password: string = '';
  private _currentDataUser: any = {};
  private _phone: string = '';
  private _currentCredentials: any;
  private _loginSuccessRedirectUrl!: string;

  public get userAuthenticationSubject() {
    return this._isUserAuthenticated;
  }

  public set loginSuccessRedirectUrl(url: string) {
    this._loginSuccessRedirectUrl = url;
  }
  public get loginSuccessRedirectUrl() {
    return this._loginSuccessRedirectUrl;
  }

  constructor(
    private phcappService: PhcAppService,
    private readonly httpService: HttpService
  ) {
    const clientMetadata: ClientMetaData = {
      hostTenantId: this.phcappService.tenant.tenantId,
    };
    Amplify.configure({
      Auth: environment.cognito,
      clientMetadata,
    });

    this._isUserAuthenticated = new BehaviorSubject<boolean>(false);
    this._currentCredentials = null;
    this._loginSuccessRedirectUrl = `${environment.carePlanUrl}`;
  }

  public get currentCredentials(): ICredentials {
    return this._currentCredentials;
  }

  public get phone(): string {
    return this._phone;
  }

  public get username(): string {
    return this._username || (sessionStorage.getItem('username') ?? '');
  }

  public set phone(phone: string) {
    this._phone = phone;
  }

  public set currentCredentials(currentCredentials: ICredentials) {
    this._currentCredentials = currentCredentials;
  }

  public set username(username: string) {
    sessionStorage.setItem('username', username);
    this._username = username;
  }

  public get password(): string {
    return this._password ?? '';
  }

  public set password(password: string | null) {
    this._password = password ?? '';
  }

  public get cognitoUser(): CognitoUser {
    return isObjectEmpty(this._cognitoUser)
      ? JSON.parse(sessionStorage.getItem('currentCognitoUser') ?? '')
      : this._cognitoUser;
  }

  public set cognitoUser(cognitoUser: CognitoUser) {
    sessionStorage.setItem('currentCognitoUser', JSON.stringify(cognitoUser));
    this._cognitoUser = cognitoUser;
  }

  public get currentDataUser(): any {
    const dataUser = !isObjectEmpty(this._currentDataUser)
      ? this._currentDataUser
      : JSON.parse(sessionStorage.getItem('currentDataUser') ?? '');
    return mergeArrayOfObjects(dataUser.UserAttributes, {
      index: 'Name',
      value: 'Value',
    });
  }

  public set currentDataUser(currentDataUser: any) {
    sessionStorage.setItem('currentDataUser', currentDataUser);
    this._currentDataUser = JSON.parse(currentDataUser);
  }

  public async signUp(user: VividCognitoUser): Promise<ISignUpResult> {
    const signUpResult: ISignUpResult = await Auth.signUp({
      username: user.username,
      password: user.password,
      attributes: {
        email: user.attributes.email,
        phone_number: user.attributes.phone_number,
        given_name: user.attributes.firstName,
        family_name: user.attributes.lastName,
        ['custom:title']: user.attributes.title,
        ['custom:approvalStatus']: 'PendingApproval',
        ['custom:workplaceCode']: user.attributes.workplaceCode,
        ['custom:NPI']: user.attributes.NPI,
        ['custom:tenantId']: user.attributes.tenantId,
        ['custom:userRole']: UserRoleTypesEnum.standard_user,
      },
    });

    this.cognitoUser = signUpResult.user;

    return signUpResult;
  }

  public async confirmSignUp(code: string): Promise<any> {
    return await Auth.confirmSignUp(this.username, code);
  }

  public async signIn(
    username: string,
    password: string
  ): Promise<CognitoUser> {
    this.password = password;
    this.username = username;
    try {
      const cognitoUser: CognitoUser = await Auth.signIn(username, password);
      this._isUserAuthenticated.next(true);
      this.cognitoUser = cognitoUser;
      this.phone = this.getPhoneFromCognitoUser(cognitoUser as any);
      return cognitoUser;
    } catch (err: any) {
      throw err;
    }
  }

  public async signOut(): Promise<void> {
    return Auth.signOut().then(() => {
      this._isUserAuthenticated.next(false);
      sessionStorage.clear();
      localStorage.clear();
      document.location.reload();
    });
  }

  public async sendPasswordResetEmail(username: string): Promise<any> {
    try {
      return await Auth.forgotPassword(username, {
        baseUrl: window.location.origin,
      });
    } catch (err: any) {
      throw err;
    }
  }

  public async isAuthenticated(): Promise<boolean> {
    if (this._isUserAuthenticated.value) {
      return Promise.resolve(true);
    } else {
      return this.getUser()
        .then((user: any): boolean => !!user)
        .catch((): boolean => false);
    }
  }

  public async getUser(): Promise<any> {
    return Auth.currentUserInfo();
  }

  public getUsers(): Observable<IdpUserAttribute[]> {
    const url = environment.tenantApiBaseUrl.concat(
      `/tenant/${this.phcappService.tenant.tenantId}/users`
    );

    const { idToken } = this.phcappService;

    if (!idToken) {
      this.phcappService.idToken = JSON.parse(
        sessionStorage.getItem('userTokens') ?? '{}'
      )?.idToken;
    }

    return this.httpService.get(
      url,
      getHttpHeaders(this.phcappService.idToken)
    );
  }

  public async updateUser(attributes: UpdatableAttributes): Promise<string> {
    const cognitoUser = await Auth.currentUserPoolUser();
    return Auth.updateUserAttributes(cognitoUser, attributes);
  }

  public updateUserAttributes(
    userName: string,
    attribute: any
  ): Observable<any> {
    const tenantEndpoint = `${environment.tenantApiBaseUrl}/tenant/${this.phcappService.tenant.tenantId}/user/${userName}`;
    const getHttpHeaders = () => {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          ...(this.phcappService.idToken && {
            Authorization: `Bearer ${this.phcappService.idToken}`,
          }),
        }),
      };
    };
    return this.httpService.patch<void>(tenantEndpoint, attribute, {
      ...getHttpHeaders(),
      observe: 'response',
    });
  }

  public async changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<'SUCCESS'> {
    const cognitoUser = await Auth.currentUserPoolUser();
    return Auth.changePassword(cognitoUser, oldPassword, newPassword);
  }

  /**
   * Verifies is a user exists. TODO: This function should be refactored to use the
   * tenant service (getUserByEmail) for the verification of an existing user.
   * @param user
   * @returns
   */
  public async userExist(user: VividCognitoUser): Promise<any> {
    try {
      await Auth.signIn({
        username: user.username,
        password: dummyPassword,
      });
    } catch (error: any) {
      const codes: { [key: string]: string | null } = {
        [CognitoCodeErrors.UsernameExistsException]: 'account/signIn',
        [CognitoCodeErrors.NotAuthorizedException]: 'account/signIn',
        [CognitoCodeErrors.UserLambdaValidationException]: 'account/signIn',
        [CognitoCodeErrors.UserNotFoundException]: 'account/create',
        [CognitoCodeErrors.InvalidPasswordException]: 'account/create',
        [CognitoCodeErrors.PasswordResetRequiredException]: 'forgotPassword',
        [CognitoCodeErrors.UserNotConfirmedException]: 'verifyUser',
        [CognitoCodeErrors.LimitExceededException]: null,
      };

      return codes[error.code];
    }
  }

  public async resetPassword(
    username: string,
    code: string,
    newPassword: string
  ): Promise<void> {
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
    } catch (error: any) {
      throw error;
    }
  }

  public async setPreferredMFA(
    method: 'TOTP' | 'SMS' | 'NOMFA' | 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA'
  ): Promise<string> {
    return await Auth.setPreferredMFA(this._cognitoUser, method);
  }

  public async resendSignUp(username: string): Promise<any> {
    return await Auth.resendSignUp(username);
  }

  public async resendCode(): Promise<CognitoUser> {
    return await this.signIn(this.username, this.password);
  }

  public async confirmSignIn(code: string): Promise<CognitoUser | any> {
    this.password = null;
    return await Auth.confirmSignIn(
      this.cognitoUser,
      code,
      <'SMS_MFA' | 'SOFTWARE_TOKEN_MFA' | null>this.cognitoUser.challengeName
    );
  }

  private getPhoneFromCognitoUser(cognitoUser: {
    storage: { [key: string]: any };
    userDataKey: string;
  }): string {
    if (cognitoUser?.storage[cognitoUser?.userDataKey]) {
      return JSON.parse(
        cognitoUser?.storage[cognitoUser?.userDataKey] ?? []
      ).UserAttributes.find(
        (e: { Name: string; Value: string }): boolean =>
          'phone_number' === e.Name
      ).Value;
    }
    return 'your phone';
  }
}
